var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-wrap"},[_c('h1',{staticClass:"tit-h1 d-flex"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" "),_c('div',{staticClass:"ml-auto align-self-center"},[_c('v-icon',{staticClass:"svg-close20",attrs:{"x-small":""},on:{"click":function($event){[_vm.disconnect(),_vm.$emit('hide')]}}})],1)]),_c('div',{staticClass:"modal-box chat-dialog"},[_c('div',{staticClass:"layout-chatmain--chat-history-box"},[_vm._l((_vm.talkHistory),function(item,index){return [(item.SNDRCV_CD == 'RCV')?_c('div',{staticStyle:{"background":"transparent !important","text-align":"left","padding":"0 0 0 6px"}},[_vm._v(_vm._s(item.CUSTOMER_NM))]):_vm._e(),(item.SNDRCV_CD == 'SND')?_c('div',{staticStyle:{"background":"transparent !important","text-align":"right","padding":"0 0 0 6px"}},[_vm._v(_vm._s(item.USER_ID))]):_vm._e(),_c('div',{key:index,class:item.SNDRCV_CD == 'RCV'
            ? 'layout-chatmain--chat-history--cs-msg'
            : 'layout-chatmain--chat-history--manager-msg'},[((item.TYPE == 'IM' || item.TYPE == 'photo'))?_c('img',{directives:[{name:"auth-image",rawName:"v-auth-image",value:(_vm.getImageSrc(item)),expression:"getImageSrc(item)"}],staticStyle:{"max-width":"100%","height":"auto"}}):_vm._e(),(item.TYPE != 'IM' && item.TYPE != 'photo')?_c('div',[_vm._v(" "+_vm._s(item.CONTENT)+" ")]):_vm._e(),_c('div',{staticClass:"layout-chatmain--chat-history--time"},[_vm._v(" "+_vm._s(item.CHAT_DATE)+" ")])])]})],2),(_vm.HAS_BTN)?[_c('div',{staticClass:"layout-chatmain--chat-history-btn"},[_c('div',{staticClass:"text-right mt-2"},[_c('v-btn',{staticClass:"btn-point ml-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.showAlertEndTalk()}}},[_vm._v(" 상담종료 ")])],1)]),_c('div',{staticClass:"layout-chatmain--chat-history--form mt-2"},[_c('v-textarea',{staticClass:"layout-chatmain--chat-history--form-textarea textarea-type0",attrs:{"label":"","no-resize":"","outlined":"","placeholder":"내용을 입력하세요","background-color":"white","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMsg.apply(null, arguments)}},model:{value:(_vm.CHAT_MSG),callback:function ($$v) {_vm.CHAT_MSG=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"CHAT_MSG"}})],1),_c('div',{staticClass:"layout-chatmain--chat-history--func mt-2"},[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-main-chatfunc pa-0",attrs:{"dark":"","small":"","min-width":"32px","height":"32px","plain":""},on:{"click":function($event){return _vm.showDialogImg('M110102P01')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"svg-main-chatfunc-img",attrs:{"small":""}})],1)]}}],null,false,4238091961)},[_c('span',[_vm._v("이미지전송")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-main-chatfunc pa-0 ml-2",attrs:{"dark":"","small":"","min-width":"32px","height":"32px","plain":""},on:{"click":_vm.sendEndLink}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"svg-main-chatfunc-send",attrs:{"small":""}})],1)]}}],null,false,3340762874)},[_c('span',[_vm._v("종료링크전송")])]),_c('v-btn',{staticClass:"btn-point ml-2",staticStyle:{"float":"right"},attrs:{"outlined":""},on:{"click":_vm.sendMsg}},[_vm._v(" 전송 ")])],1)]),_c('div',{},[_c('v-dialog',{attrs:{"width":"800","height":"700","content-class":"square-modal min-auto"},scopedSlots:_vm._u([{key:"body",fn:function(){return undefined},proxy:true}],null,false,2020317593),model:{value:(_vm.dialogM110102P01),callback:function ($$v) {_vm.dialogM110102P01=$$v},expression:"dialogM110102P01"}},[_c('dialog-M110102P01',{attrs:{"headerTitle":"이미지 전송","Imgdata":_vm.Imgdata},on:{"hide":function($event){return _vm.hideDialog('M110102P01')},"input":function($event){_vm.Imgdata = $event},"selImg":function($event){[_vm.hideDialog('M110102P01'), _vm.getTalkHistList()]}}})],1)],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }