<template>
  <div class="modal-wrap">
  <div class="d-flex">
    <div class="flex-grow-1">
      <h3>이미지 전송</h3>
      <div class="list-box mt-3 pa-3 white">
        <div class="box-ct d-flex">
          <div class="">
            <div class="form-inp">
              <v-text-field
                class="form-inp"
                name="name" label="제목" outlined hide-details
                v-model="BRD_TIT">
                <template v-slot:label>
                  제목
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
          <div class="ml-auto align-self-center">
            <v-btn outlined class="btn-etc2 ml-3"  v-on:click="getImageList">조회</v-btn>
          </div>
        </div>
      <template>
        <table style="display: grid;">
          <div class="image_list">

            <v-card
                class="mx-auto"
            >
              <v-container fluid v-if="historyList.length > 0">
                <v-row
                       v-for=" i in rows"
                       :key="i">
                  <v-col
                      v-for="j in cols"
                      :key="j">
                    <v-card v-if="i == 1 && historyList[j-1] != undefined"
                          v-on:click="getImgInfo(1,cols,j)" :dblclick="sendImg"
                        >
                      <v-card-title v-text="historyList[j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(1,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="100px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 2 && historyList[cols + j-1] != undefined"
                        v-on:click="getImgInfo(2,cols,j)" :dblclick="sendImg"
                      >
                      <v-card-title v-text="historyList[cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(2,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="100px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 3 && historyList[cols + cols + j-1] != undefined "
                          v-on:click="getImgInfo(3,cols,j)" :dblclick="sendImg"
                        >
                      <v-card-title v-text="historyList[cols + cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(3,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="100px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </table>
      </template>
      <div class="grid-paging text-center pt-2 mb-5">
        <v-pagination class="pagination mb-2" v-model="page" :length="pages" @input="updatePage"></v-pagination>
      </div>
        <div class="box-ct d-flex">
          <div class="ml-auto align-self-center">
            <v-btn outlined class="btn-etc2 ml-3"  v-on:click="sendImg">전송</v-btn>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil.js";
import {mixin} from "@/mixin/mixin.js";

import { stompUtil }  from "@/store/stomp-util.js";
import moment from "moment";

export default {
    name: "MENU_M110102P01", //name은 'MENU_' + 파일명 조합
    mixins    : [mixin], // mixin 등록

  components: {
  },
  props: {
    Imgdata: {
      type: Object,
      value:{}
    },
  },
  data() {
    return {

      ASP_CUST_KEY: '',
      BRD_TIT  : '', // 검색할 제목
      BRD_ID:'4',
      PAGES_CNT: 1, // header 현재 보여지는 페이지 기본 1
      ROW_CNT  : 1000, // 한 페이지에 보여줄 게시글의 수
      pageCount: 1,

      TOT_COUNT: '', // 총 게시글 수

      // as is list data
      FST_BRD_DT  : '',
      PATH_TYPE   : '',
      FILE_KEY    : '',
      FILE_NM     : '',
      FST_BRD_DT_D: '',

      //페이징
      page       : 1,
      pageSize   : 6,
      listCount  : 0,
      historyList: [],
      rows       : 2,

      // css 열 바꿈
      articles: [],
      cols    : 3,

      CLK_IMG:{},
    };
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },
  },
  watch: {},
  created() {
    this.ASP_CUST_KEY = this.Imgdata.ASP_NEWCUST_KEY;
    this.getImageList();
  },
  mounted() {
  },
  methods: {

    async getImageList() { // 조회 버튼 클릭
    
      let DATE = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      let ST_DATE = moment(DATE).subtract(1, "year").format("YYYYMMDD");
      let END_DATE = moment(ST_DATE).add(1, "year").format("YYYYMMDD");;

      this.historyList = [];
      api
        .post(
          "api/chat/setting/image-manage/list", //api url입력
          {
            ASP_NEWCUST_KEY: '001',
            BRD_ID: this.BRD_ID,
            START_DT: ST_DATE,
            END_DT: END_DATE,
            BRD_TIT: this.BRD_TIT,
            PAGES_CNT: '1',
            ROW_CNT: '1000',
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: '001',
          },
          {
            head: {
              SERVICE: "chat.setting.image-manage",
              METHOD: "list",
              TYPE: "BIZ_SERVICE",
              ROW_CNT: "200",
              PAGES_CNT: "1",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          this.setImageList(response.data);
        })
        .catch((err) => {
          alert(err);
        });
      },

    //이미지 리스트 설정
    async setImageList(response) {
      let header = response.HEADER;
      // let data = response.DATA;
      // this.gridListDataText = response.DATA;

      // this.articles = Object.assign([], data);
      let data = await this.mixin_imageView('chat', 'images', response.DATA);
      console.log("data");

      console.log(data);
      console.log("data");
      // this.gridListDataText = response.DATA;
      this.articles = Object.assign([], data);

      //페이징
      this.initPage(this.pageSize);

    },

    // 3, 4, 5, 6, 7열 클릭 시 보여주는 게시글 조절
    changeRow(cols, pageSize) {
      //histroyList =  한 페이지에 들어가는 articles
      this.historyList = this.articles.slice(0, pageSize);
      this.cols = cols; //3
      this.pageSize = pageSize;

      //row 구하기
      let totalLength = this.historyList.length; //6
      if(totalLength % cols > 0){
        this.rows = Math.ceil(totalLength/cols);
      } else{
        this.rows = totalLength/cols;
      }

      //페이징
      this.initPage();

    },

    //페이지 초기화
    initPage() {
      let _this = this;
      _this.page = 1;
      _this.listCount = _this.articles.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.articles;
      } else {
        _this.historyList = _this.articles.slice(0, _this.pageSize);
      }
    },

    //페이지 업데이트
    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.articles.slice(_start, _end);
      _this.page = pageIndex;
    },

    //이미지 경로 설정
    // getFileSrc(num,cols,j){
    //   var strImageUrl = "";
    //   if (num == 1){
    //     strImageUrl = `url(/upload/${this.historyList[j-1].FILE_PATH}/${this.historyList[j-1].SAVE_FILENAME})`;
    //   } else if (num == 2) {
    //     strImageUrl = `url(/upload/${this.historyList[cols + j-1].FILE_PATH}/${this.historyList[cols + j-1].SAVE_FILENAME})`;
    //   } else if (num == 3) {
    //     strImageUrl = `url(/upload/${this.historyList[cols + cols + j-1].FILE_PATH}/${this.historyList[cols + cols + j-1].SAVE_FILENAME})`;
    //   } 
    //   return strImageUrl;
    // },
    getFileSrc(row,cols,j){

      let ojbIdx  = (row-1) * cols;
      let strImageUrl = "";

      if (row == 1){
         strImageUrl = `url(${this.historyList[ojbIdx + j-1].src})`;
      } else if (row == 2) {
        strImageUrl = `url(${this.historyList[ojbIdx + j-1].src})`;
       } else if (row == 3) {
        strImageUrl = `url(${this.historyList[ojbIdx + j-1].src})`;
      }
       return strImageUrl;
    },

    //이미지 정보 가져오기
    getImgInfo(num,cols,j){
      console.log("this.historyList", this.historyList);
      let strImageUrl = "";
      if (num == 1){
        this.CLK_IMG = this.historyList[j-1];
      } else if (num == 2) {
        this.CLK_IMG = this.historyList[cols + j-1];
      } else if (num == 3) {
        this.CLK_IMG = this.historyList[cols + cols + j-1];
      } 
    },

    //이미지 전송
    sendImg(){
      
      let IMG_INFO = {message_type : 'IM', SNDRCV_CD: 'SND', type : 'image'
                , image : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , msg : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , CONTENT : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , IMAGE_TALK_PATH : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , FILE_GROUP_KEY : this.CLK_IMG.FILE_GROUP_KEY
                , FILE_KEY : this.CLK_IMG.FILE_KEY
              };

      let CHAT_INFO = {'talk_contact_id' : this.Imgdata.CLK_CHAT
                      , 'talk_sender_key' : this.Imgdata.CLK_SENDER
                      , 'call_typ_cd' : 'TTALK'
                      , 'aspNewCustKey' : this.Imgdata.CLK_CUST
                      , 'talk_user_key': this.Imgdata.CLK_USER
                      , 'SEND_USER_NM' : this.Imgdata.USER_NM
                      , 'token' : this.$store.state.messages.token
                      , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                      };

      let chatmsg = this.$store.getters['messages/GE_MSG_OBJ']({
                    user_key: CHAT_INFO.talk_user_key
                    , sndrcv_cd: 'snd'
                    , type: IMG_INFO.message_type
                    , content: IMG_INFO.msg
                    , talk_contact_id: CHAT_INFO.talk_contact_id
                    , IMAGE_TALK_PATH : IMG_INFO.IMAGE_TALK_PATH != '' ? IMG_INFO.IMAGE_TALK_PATH : ''
                    , FILE_GROUP_KEY : IMG_INFO.FILE_GROUP_KEY != '' ? IMG_INFO.FILE_GROUP_KEY : ''
                    , FILE_KEY : IMG_INFO.FILE_KEY != '' ? IMG_INFO.FILE_KEY : ''
                    , FILE_BLOB : IMG_INFO.FILE_BLOB != '' ? IMG_INFO.FILE_BLOB : ''
                    , FILE_EXTS : IMG_INFO.FILE_EXTS != '' ? IMG_INFO.FILE_EXTS : ''
                    //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                    , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                    , SEND_USER_SHORT_NM : '나'
                    , consultEnd : IMG_INFO.consultEnd
                    , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                });

      let msgObj = this.$store.getters['messages/GE_CONTACT_MSG']({ talk_contact_id: CHAT_INFO.talk_contact_id });

      stompUtil.sendMessage(IMG_INFO, CHAT_INFO, chatmsg, msgObj);
      this.$emit("selImg", "getTalkInfo");
      this.$emit('hide');
    },
  },

  watch: {
    Imgdata(){
        console.log("here11",this.Imgdata);
      this.ASP_CUST_KEY = this.Imgdata.CLK_CUST;
      this.getImageList();
    }
  }
};
</script>

<style></style>
