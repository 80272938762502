<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>상담중 현황 및 3자 채팅</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="40px">
                    <col width="150px">
                    <col width="150px">
                    <col width="150px">
                    <col width="10px">
                    <col width="150px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>상담사</label></th>
                    <!-- <td><v-select class="jh-form" :items="USER_ATTR_A" item-text="CD_NM" item-value="CD" placeholder="전체" v-model="formData.USER_ATTR_A" @change="changeDept(formData.USER_ATTR_A)"></v-select></td>
                    <td><v-select class="jh-form" :items="USER_ATTR_B" item-text="CD_NM" item-value="CD" placeholder="전체" v-model="formData.USER_ATTR_B" @change="changeUser(formData.USER_ATTR_A, formData.USER_ATTR_B)"></v-select></td>
                    <td><v-select class="jh-form" :items="CNSL_COMBO" item-value="USER_ID" item-text="USER_NM" v-model="formData.USER_ID"></v-select></td> -->

                    <td><v-select class="jh-form" v-model="formData.USER_ATTR_A" :items="USER_ATTR_A" item-value="CD" item-text="CD_NM" @change="changeDept(formData.USER_ATTR_A)"></v-select></td>
                    <td><v-select class="jh-form" v-model="formData.USER_ATTR_B" :items="USER_ATTR_B" item-value="CD" item-text="CD_NM" @change="changeUser(formData.USER_ATTR_A, formData.USER_ATTR_B)"></v-select></td>
                    <td><v-select class="jh-form" v-model="formData.USER_ID" item-value="USER_ID" item-text="USER_NM" :items="CNSL_COMBO" ></v-select></td>
                    <!-- <v-btn class="jh-btn has-icon-only is-fill" @click="[setUser(), showDialog('P101801')]"><i class="jh-icon-search is-white"></i></v-btn> -->
                    <td><v-btn class="jh-btn is-fill has-icon-only" @click="[setUser(), showDialog('P101801')]"><i class="jh-icon-search-sm is-white"></i></v-btn></td>
                    <!--
                    <td><v-select class="jh-form" :items="USER_ATTR_C" item-text="CD_NM" item-value="CD" placeholder="전체" v-model="formData.USER_ATTR_C"></v-select></td>
                    <th><label>상담사</label></th>
                    <td>
                        <div class="jh-cols">
                            <v-text-field class="jh-form is-col-fix" style="width: 80px;" name="name" id="id" placeholder="상담사ID" v-model="formData.USER_ID"></v-text-field>
                            <v-text-field class="jh-form" name="name" id="id" placeholder="상담사명" v-model="formData.USER_NM"></v-text-field>
                            <v-btn class="jh-btn has-icon-only is-fill" @click="[setUser(), showDialog('P101801')]"><i class="jh-icon-search is-white"></i></v-btn>
                            <v-btn class="jh-btn has-icon-only" @click="clearData"><i class="jh-icon-refresh"></i></v-btn>
                        </div>
                    </td>
                    -->
                    <th style="width: 80px;"><label>고객ID</label></th>
                    <td style="width: 100px;">
                        <div class="jh-cols">
                            <v-text-field class="jh-form is-col-fix" style="width: 100px;" name="name" id="CUST_NO" placeholder="고객ID" @keyup.enter="selectRtnCnslMgmt" v-model="formData.CUST_NO"></v-text-field>
                        </div>
                    </td>
                    <td class="has-search" @click="selectRtnCnslMgmt"><v-btn class="jh-btn is-search">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>상담현황목록</h2>
                <!--
                <div class="is-right">
                  <v-btn class="jh-btn" @click="openP101103">임시채팅팝업</v-btn>
                </div>
                -->
            </div>
            <data-tables
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            :paginationOptions="paginationOptions"
            >
            <template v-slot:header>
                <thead class="v-data-table-header">
                  <tr>
                    <th rowspan="2" width="40px"><span>NO</span></th>
                    <th rowspan="2" width="150px"><span>상담사명/사번</span></th>
                    <th rowspan="2" width="100px"><span>처리상태</span></th>
                    <th colspan="4" width="450px"><span>고객정보</span></th>
                    <th colspan="3"><span>문의유형</span></th>
                    <th rowspan="2" width="120px">3자채팅</th>
                    <th rowspan="2" width="120px"><span>상담요청일시</span></th>
                    <th rowspan="2" width="80px"><span>대기시간</span></th>
                    <th rowspan="2" width="120px"><span>상담시작일시</span></th>
                  </tr>
                  <tr>
                    <th><span>회원/비회원</span></th>
                    <th><span>고객명</span></th>
                    <th><span>고객ID</span></th>
                    <th><span>계좌번호</span></th>
                    <th><span>1단계</span></th>
                    <th><span>2단계</span></th>
                    <th><span>3단계</span></th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item.THIRD_CHAT="{ item }">
                <!--
                  버튼권한을 줄 수 없음
                  <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnHear')" outlined small class="btn-point ml-2">녹취</v-btn>
                -->
                <v-btn outlined small class="btn-point ml-2" @click="openE101103P01(item)">3자팝업</v-btn>
              </template>
            </data-tables>
            <!-- <v-data-table
            dense
            height="539px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            hide-default-header
            hide-default-footer
            class="grid-default mt-6"
            @page-count="pageCount = $event"
            no-data-text="검색된 결과가 없습니다."
            >
            <template v-slot:header>
                <thead class="v-data-table-header">
                <tr>
                    <th scope="row" rowspan="2" class="text-center">NO</th>
                    <th scope="row" rowspan="2" class="text-center">사용자ID</th>
                    <th scope="row" rowspan="2" class="text-center">사용자명</th>
                    <th scope="row" rowspan="2" class="text-center">
                    사용자닉네임
                    </th>
                    <th scope="row" rowspan="2" class="text-center">처리상태</th>
                    <th scope="row" rowspan="2" class="text-center">상담톡</th>
                    <th scope="row" rowspan="2" class="text-center">상담ID</th>
                    <th scope="row" colspan="3" class="text-center">고객정보</th>
                    <th scope="row" rowspan="2" class="text-center">
                    문의유형1단계
                    </th>
                    <th scope="row" rowspan="2" class="text-center">
                    문의유형2단계
                    </th>
                    <th scope="row" rowspan="2" class="text-center">
                    상담요청일시
                    </th>
                    <th scope="row" rowspan="2" class="text-center">대기시간</th>
                    <th scope="row" rowspan="2" class="text-center">
                    상담시작일시
                    </th>
                </tr>
                <tr>
                    <th scope="row" class="text-center">회원/비회원</th>
                    <th scope="row" class="text-center">API ID</th>
                    <th scope="row" class="text-center">UID</th>
                </tr>
                </thead>
            </template>
            </v-data-table>
            <div class="grid-paging text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
            </div> -->
        </div>
        <!-- 채팅내역 모달 -->
        <v-dialog
          max-width="655"
          v-model="DialogChat"
          content-class="square-modal modal-white">
          <dialog-Chat 
            headerTitle="채팅내역" 
            @hide="hideDialog('Chat')" 
            HAS_BTN
            :chatInfo="chatInfo"
            :params="chatInfo"
            @chatEnd="[hideDialog('Chat'), showAlert('상담이 종료되었습니다.'), selectRtnCnslMgmt()]"
          ></dialog-Chat>
          <template v-slot:body></template>
        </v-dialog>
        <v-dialog max-width="1000" v-model="DialogP101801">
            <dialog-P101801
            @hide="hideDialog('P101801')"
            :userInfo="userInfo"
            @input="userInfo = $event"
            @selectUser="[hideDialog('P101801'), setUserData()]"
            ></dialog-P101801>
            <template v-slot:body></template>
        </v-dialog>
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import apiChat from "../../store/apiChatParamUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import DialogP101801 from "./P101801.vue";
import dataTables from "@/components/DataTables";
import DialogChat from "./ChatHistoryModal.vue";
export default {
  name: "MENU_E020203", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogP101801,
    dataTables,
    DialogChat
  },
  data: () => ({
    DialogP101801: false,	
    DialogChat: false,
    userInfo: {},
    chatInfo:{},
    USER_ATTR_A: [{CD_NM: "전체", CD: ""}],
    USER_ATTR_B: [{CD_NM: "전체", CD: ""}],
    USER_ATTR_C: [],
    USER_ATTR_D: [],
    CNSL_COMBO: [
      {
        USER_ID: "",
        USER_NM: "전체",
      },
    ],
    formData: {
      USER_ID: "",
      USER_NM: "",
      USER_NICK: "",
      USER_ATTR_A: "",
      USER_ATTR_B: "",
      USER_ATTR_C: "",
      USER_ATTR_D: "",
      ASP_CUST_KEY: "001",
      ASP_NEWCUST_KEY: "001",
      CUST_NO: "",
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    dropItems: [{ title: "한국클라우드", value: "001" }],
    gridDataHeaders: [
      { text: "", value: "index", align: "center", sortable: false }, //No
      { text: "", value: "USER_ID_NM", align: "center", sortable: false }, //사용자ID
    //   { text: "", value: "USER_NM", align: "center", sortable: false }, //사용자명
    //   { text: "", value: "USER_NICK", align: "center", sortable: false }, //사용자닉네임
      { text: "", value: "TALK_STAT_NM", align: "center", sortable: false }, //처리상태
      // { text: "", value: "CNSL_SUPPORT", align: "center", sortable: false }, //상담톡
      // { text: "", value: "TALK_USER_KEY", align: "center", sortable: false }, //상담ID
      { text: "", value: "CUSTOMER_MEMBER", align: "center", sortable: false }, //(하위)회원/비회원
      { text: "", value: "CUSTOMER_NM", align: "center", sortable: false }, //고객명
      { text: "", value: "CUSTOMER_ID", align: "center", sortable: false }, //고객ID
      { text: "", value: "TID", align: "center", sortable: false }, //계좌번호
      { text: "", value: "TALK_INQRY_NM_1", align: "center", sortable: false }, //문의유형1단계
      { text: "", value: "TALK_INQRY_NM_2", align: "center", sortable: false }, //문의유형2단계
      { text: "", value: "TALK_INQRY_NM_3", align: "center", sortable: false }, //문의유형3단계
      { text: "", value: "THIRD_CHAT", align: "center", sortable: false }, //3자채팅버튼
      { text: "", value: "TALK_DIST_DT", align: "center", sortable: false }, //상담요청일시
      { text: "", value: "WAIT_TIME", align: "center", sortable: false }, //대기시간
      { text: "", value: "TALK_START_DT", align: "center", sortable: false }, //상담시작일시
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        hideDefaultHeader: true,
        height: '600px',
        itemKey: 'index',
        items:[],
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true
    },
    paginationOptions: {
        totalVisible: 10
    },
    btnList: [],

    RDOnly:false,
  }),
  methods: {
    onClickRow(){

    },
    selectValidation() {
      if (
        this.formData.ASP_NEWCUST_KEY == "" ||
        this.formData.ASP_NEWCUST_KEY == undefined
      ) {
        this.common_alert("회사를 먼저 선택해주세요.", "error");
        return false;
      }
      return true;
    },
    showDialog(type) {
      if (!this.selectValidation()) return;
      this[`Dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`Dialog${type}`] = false;
    },
    setUser() {
      let user = {
        ASP_CUST_KEY: this.formData.ASP_NEWCUST_KEY,
        USER_ID: this.formData.USER_ID,
        USER_NICK: this.formData.USER_NICK,
        USER_NM: this.formData.USER_NM,
      };
      this.userInfo = user;
    },
    async setUserData() {
      // this.formData.USER_ID = this.userInfo.USER_ID;
      // this.formData.USER_NM = this.userInfo.USER_NM;
      // this.formData.USER_NICK = this.userInfo.USER_NICK;

            this.formData.USER_ID = this.userInfo.USER_ID;
            // this.formData.USER_NM = this.userInfo.USER_NM;

            this.USER_ATTR_B = await this.mixin_get_attr_by_attr('', this.userInfo.USER_ATTR_A);
            this.CNSL_COMBO = await this.mixin_get_user_by_attr(this.userInfo.USER_ATTR_A, this.userInfo.USER_ATTR_B);

            this.formData.USER_ATTR_A  = this.userInfo.USER_ATTR_A;
            this.formData.USER_ATTR_B  = this.userInfo.USER_ATTR_B;
            this.formData.USER_ID = this.userInfo.USER_ID;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    async selectRtnCnslMgmt() {
      if (!this.selectValidation()) return;
      let URLData = "api/chat/status/cnslt-list/inqire";
      let HEADER_SERVICE = "chat.status.cnslt-list";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;

      let jsonData = this.formData;
      console.log(this.formData)

      //이 작업 안할시 Observer 객체가 들어가서 데이터 미선택시 조회가 안됨..
      jsonData.USER_ATTR_A =
        this.formData.USER_ATTR_A.length == 0 ? "" : this.formData.USER_ATTR_A;
      jsonData.USER_ATTR_B =
        this.formData.USER_ATTR_B.length == 0 ? "" : this.formData.USER_ATTR_B;
      jsonData.USER_ATTR_C =
        this.formData.USER_ATTR_C.length == 0 ? "" : this.formData.USER_ATTR_C;
      jsonData.USER_ATTR_D =
        this.formData.USER_ATTR_D.length == 0 ? "" : this.formData.USER_ATTR_D;
      jsonData.USER_ID =
        this.formData.USER_ID.length == 0 ? "" : this.formData.USER_ID  ;
      jsonData.USER_NM =
        this.formData.USER_NM.length == 0 ? "" : this.formData.USER_NM;                

      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
          },
        })
        .then((response) => {
          if (response.data.HEADER.ERROR_FLAG == false) {
            const jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
            for (let i = 0; i < jsonObj.length; i++) {
              jsonObj[i].index = i + 1;
              jsonObj[i].USER_ID_NM = (jsonObj[i].USER_NM == '')? `(${jsonObj[i].USER_ID})` : (`${jsonObj[i].USER_NM}(${jsonObj[i].USER_ID})`);
            }
            this.gridDataText = jsonObj;
            this.dataTableOptions.items = jsonObj;
          } else {
            this.dataTableOptions.items = [];
            this.gridDataText = [];
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    async changeAspCustKey() {
      let aspNewcustKey = this.formData.ASP_NEWCUST_KEY;
      this.formData.ASP_CUST_KEY = this.formData.ASP_NEWCUST_KEY;

      this.USER_ATTR_A = await this.mixin_get_attr_by_attr('****', '');
      //this.USER_ATTR_B = await this.mixin_attr_get(aspNewcustKey, "B", "전체");
      //this.USER_ATTR_C = await this.mixin_attr_get(aspNewcustKey, "C", "전체");
      //this.USER_ATTR_D = await this.mixin_attr_get(aspNewcustKey, "D", "전체");

      this.formData.USER_ATTR_A = "";
      this.formData.USER_ATTR_B = "";
      this.formData.USER_ATTR_C = "";
      this.formData.USER_ATTR_D = "";
      this.formData.USER_ID = '';
    },
    async changeDept(attrA){
        // this.USER_ATTR_B = await this.mixin_get_attr_by_attr('', attrA);
        // this.CNSL_COMBO = [{USER_ID: "",USER_NM: "전체",},];
        // this.formData.USER_ATTR_B = '';
        // this.formData.USER_ID = '';

            if(this.mixin_isEmpty(attrA)) {
                this.USER_ATTR_B = [{CD: '', CD_NM: '전체'}];
                this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
            } else {
                this.USER_ATTR_B = await this.mixin_get_attr_by_attr('', attrA);
            }
            this.formData.USER_ID = '';
            this.formData.USER_ATTR_B = '';        
    },
    async changeUser(attrA, attrB){
        // this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
        // this.formData.USER_ID = '';

            if(this.mixin_isEmpty(attrB)) {
                this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
            } else {
                this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
            }
            this.formData.USER_ID = '';        
    },
    async setChangeUser(USER_ID){
        // this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
        // this.formData.USER_ID = '';

            if(this.mixin_isEmpty(attrB)) {
                this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
            } else {
                this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
            }
            this.formData.USER_ID = '';        
    },    
    clearData() {
      this.formData.USER_ID = "";
      this.formData.USER_NM = "";
      this.formData.USER_NICK = "";
    },

    getChatInfo(item){
      console.log("item", item);
      let chat = {
        ASP_NEWCUST_KEY: this.formData.ASP_NEWCUST_KEY,
        TALK_CONTACT_ID: item.TALK_CONTACT_ID,
        ASP_SENDER_KEY: item.ASP_SENDER_KEY,
        TALK_USER_KEY: item.TALK_USER_KEY,
        USER_ID: item.USER_ID,
      };
      this.chatInfo = chat;

      this.chatInfo.ASP_CUST_KEY = this.chatInfo.ASP_NEWCUST_KEY;
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    openP101103(){
      this.$openWindow('P101103','P101103','500','800');
    },

    openE101103P01(item){
      this.setE101103PopupData(item);
      this.$openWindow('E101103P01','E101103P01','500','800');
    },

    //set
    setE101103PopupData(item){
      const chat = {
        ASP_NEWCUST_KEY: this.formData.ASP_NEWCUST_KEY,
        TALK_CONTACT_ID: item.TALK_CONTACT_ID,
        ASP_SENDER_KEY: item.ASP_SENDER_KEY,
        TALK_USER_KEY: item.TALK_USER_KEY,
        CUSTOMER_NM: item.CUSTOMER_NM,
        CUSTOMER_ID: item.CUSTOMER_ID,
        USER_ID: item.USER_ID,
        USER_NM: item.USER_NM,
        HAS_BTN: true,
        chatInfo: this.chatInfo,
        params: this.chatInfo,
      };
      localStorage['CHAT_POPUP_PARAM'] = JSON.stringify(chat);
    },
  },
  computed: {
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
    dataTableInstance: function() {
      return this.$refs.dataTable;
    },  
  },
  mounted() {
    //let chk = this.mixin_check_AspCustKey_number();
    
    this.formData.ASP_NEWCUST_KEY = "001";
    this.RDOnly = true;

    this.changeAspCustKey();

    // this.btnList = this.mixin_set_btn(this.$options.name);
    // let keyArray = Object.keys(this.$refs);
    // if (keyArray.length > 0) {
    //   this.$refs[keyArray[0]].$el.parentNode.removeChild(
    //     this.$refs[keyArray[0]].$el
    //   );
    // }

  },
    created() { 
        this.dataTableOptions.headers = this.gridDataHeaders;
        this.dataTableOptions.items = this.gridDataText;
    }
};
</script>

<style></style>
