<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="[disconnect(),$emit('hide')]"></v-icon>
      </div>
    </h1>
    <div class="modal-box chat-dialog">
      <div class="layout-chatmain--chat-history-box">
        <template v-for="(item, index) in talkHistory">
          <div v-if="item.SNDRCV_CD == 'RCV'" style="background: transparent !important; text-align: left; padding: 0 0 0 6px;">{{ item.CUSTOMER_NM }}</div>
          <div v-if="item.SNDRCV_CD == 'SND'" style="background: transparent !important; text-align: right; padding: 0 0 0 6px;">{{ item.USER_ID }}</div>
          <div :class="
            item.SNDRCV_CD == 'RCV'
              ? 'layout-chatmain--chat-history--cs-msg'
              : 'layout-chatmain--chat-history--manager-msg'
          " :key="index">
            <img v-if="(item.TYPE == 'IM' || item.TYPE == 'photo')" 
              v-auth-image="getImageSrc(item)"
              style="max-width: 100%; height: auto;"
            />
            <div v-if="item.TYPE != 'IM' && item.TYPE != 'photo'">
            {{ item.CONTENT }}
            </div>
            <div
              class="layout-chatmain--chat-history--time"
            >
            {{ item.CHAT_DATE }}
            </div>
          </div>
        </template>
      </div>
      <template v-if="HAS_BTN" >
        <!-- 버튼 -->
        <div class="layout-chatmain--chat-history-btn">
          <div class="text-right mt-2">
            <v-btn
              outlined
              class="btn-point ml-2"
              @click="showAlertEndTalk()"
            >
              상담종료
            </v-btn>
          </div>
        </div>
        <!-- 입력 form -->
        <div class="layout-chatmain--chat-history--form mt-2">
          <v-textarea
           label=""
           no-resize
           outlined
           placeholder="내용을 입력하세요"
           background-color="white"
           dense
           class="layout-chatmain--chat-history--form-textarea textarea-type0"
           v-model.trim="CHAT_MSG"
           v-on:keyup.enter="sendMsg"
           ></v-textarea>
        </div>
        <!-- 기능 버튼 -->
        <div class="layout-chatmain--chat-history--func mt-2">
          <div>
            <!-- 이미지 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="showDialogImg('M110102P01')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="svg-main-chatfunc-img"></v-icon>
                </v-btn>
              </template>
              <span>이미지전송</span>
            </v-tooltip>
            <!-- 종료링크전송 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="sendEndLink"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="svg-main-chatfunc-send"></v-icon>
                </v-btn>
              </template>
              <span>종료링크전송</span>
            </v-tooltip>
            <!-- 전송 -->
            <v-btn
              outlined
              class="btn-point ml-2"
              v-on:click="sendMsg"
              style="float:right;"
            >
              전송
            </v-btn>
          </div>
        </div>
        <!-- 이미지 전송 모달창 -->
        <div class="">
          <v-dialog
            width="800"
            height="700"
            v-model="dialogM110102P01"
            content-class="square-modal min-auto"
          >
            <dialog-M110102P01
              headerTitle="이미지 전송"
              @hide="hideDialog('M110102P01')"
              :Imgdata="Imgdata"
              @input="Imgdata = $event"
              @selImg="[hideDialog('M110102P01'), getTalkHistList()]"
            >
            </dialog-M110102P01>
            <template v-slot:body> </template>
          </v-dialog>
        </div>
        <!-- 이미지 전송 모달창 -->
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mixin } from "@/mixin/mixin.js"
import apiChat from "../../store/apiChatParamUtil.js";
import Vue from "vue";
import VueAuthImage from 'vue-auth-image';
import axios from "axios";

import { eventBus } from "@/store/eventBus.js"; //Event Bus
import { stompUtil } from "@/store/stomp-util.js";
import DialogM110102P01 from "./M110102P01.vue";

Vue.use(VueAuthImage);

export default {
  name: "MENU_ChatHistoryModal", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM110102P01,
  },
  props: {
    params:{
      type: Object,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    },
    HAS_BTN: {
      type: Boolean,
      default: false,
    },
    chatInfo: {
      type: Object,
      value: {},
    },
  },
  data() {
    return {
      CHAT_MSG: '',
      talkHistory: [],
      dialogM110102P01: false,
      Imgdata: {},
      ASP_NEWCUST_KEY:'',
      TALK_CONTACT_ID:'',
      ASP_SENDER_KEY:'',
      TALK_USER_KEY:'',
      USER_ID:'',

      CUST:this.fullCust(),
    }
  },
  created() {
    this.ASP_NEWCUST_KEY = this.chatInfo.ASP_NEWCUST_KEY;
    this.TALK_CONTACT_ID = this.chatInfo.TALK_CONTACT_ID;
    this.ASP_SENDER_KEY = this.chatInfo.ASP_SENDER_KEY
    this.TALK_USER_KEY = this.chatInfo.TALK_USER_KEY
    this.USER_ID = this.chatInfo.USER_ID
    
    this.getTalkHistList();

    if(this.HAS_BTN){
      //this.changeUserStat("CHATON");
      this.setTalkEnv();
      this.connect();
    }

    /**
     * 채팅 메시지 인입
     */
    eventBus.$on("busThirdInoutCallback", (message) => {
      this.getTalkList();
      this.getTalkHistList();

      let recvJson = JSON.parse(message.body);
      let destArray = message.headers.destination.split("/")
      if(recvJson.chatEvent == "EXPIRED_SESSION_CUST"){
        //message.headers.subscription.unsubscribe();
        if(recvJson.userId == this.USER_ID){
         this.$emit("chatEnd", this.chatData);
        }

      }
    });
  },
  mounted() {

  },
  methods: {
      /**
       * 이미지 src 가져오기
       */
      getImageSrc: function(message)  {
          var strImageUrl = "";

          // Authorization Header 추가 (403 Forbidden 방지)
          const token = window.sessionStorage.getItem("token");
          var authHeader = "bearer " + token;
          axios.defaults.headers.common['Authorization'] = authHeader;

          strImageUrl = `/api/file/chat/images/${message.FILE_GROUP_KEY}/${message.FILE_KEY}`;

          return strImageUrl;

      },
    //상담이력 조회
    getTalkHistList() {
      apiChat
        .post(
          "api/chat/main/cnslt-hist/chat-inqire/" + this.TALK_CONTACT_ID, //api url입력
          {
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            ASP_USER_ID: this.HAS_BTN == true ? this.USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
            USER_ID: this.HAS_BTN == true ? this.USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist/chat-inqire/" + this.TALK_CONTACT_ID,
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          //console.log("상담이력<<<<>>>>", response.data.data);
          //console.log("index", index);
          this.talkHistory = response.data.data;
          //console.log("이력메시지목록<<<<>>>>", this.talkHistList);
          if(this.HAS_BTN){
            this.scrlToBottom();
          } else {
            this.scrlToTop();
          }
        })
        .catch((err) => {
          this.mainAlert(err);
        });
    },

    /** 상담설정 관련 정보 get */
    setTalkEnv() {
      apiChat
        .post("api/chat/common-vue/env/inqry", {
          ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
        })
        .then((ret) => {
          // 서버 error 체크
          if (ret.data.error != null && ret.data.error != "") {
            if (ret.data.error == "-999") {
              this.mainAlert("챗 상담 사용자 정보 Error(Talk Token)");
            }
          } else {
            let retObj = Object.values(ret.data.data);

            if (retObj != null && retObj.length > 0) {
              this.$store.state.readyTimeout =
                retObj[0].RETRY_READY_MAX_TIMEOUT;
            }

            if (retObj != null && retObj.length > 0) {
              this.$store.state.messages.blobYn = retObj[0].BLOB_YN;
            }
          }
        });
    },

    //
    //3자채팅 웹소캣 연결
    /**
     * websocket connect
     * @param connectCallback   연결 성공 콜백
     * @param errorCallback     연결 실패 콜백 ( 재연결 시도 )
     */
    connect() {
      let geUserId = this.USER_ID;

      //const subScribeUrl = this.baseSocketUrl + "queue/ready/" + geUserId;                //"http://local.hkpalette.com:8443/queue/ready/"
      let subScribeUrl = "/queue/ready/" + geUserId;

      let chatMessage = {
        chatType: "READY",
        userId: geUserId,
        userName: geUserId,
        userNickname: geUserId,
      };

      stompUtil.token = this.$store.state.messages.token; //SOCKET 연결 시 사용자 SOCKET Token 세팅
      stompUtil.userId = geUserId;
      stompUtil.aspNewCustKey = this.CUST;

      stompUtil.connect(
        () => {
          // 연결성공( 재연결 포함 )

          stompUtil.setConnected(true);

          stompUtil.subscribe(subScribeUrl, chatMessage); // ready uri sub

          let subList = this.$store.getters["messages/GE_SUBSCRIPTION_LIST"];
          stompUtil.subscribeAfterReconnect(subList); // 재연결 후 기존 sub 목록을다시 sub 해줌.최초는 대상이 없으므로 무관

          ///// 선택된 상담이 잇다면 재조회 (재연결시...)
          ////if( this.store.getters.getContactInfo().talk_contact_id != null && .getters.getContactInfo().talk_contact_id != '' ) {
          /////  eventBus.refreshChatContent(this.store.getters.getContactInfo().talk_contact_id);
          ////}
          this.getTalkList();
        },
        (error) => {
          //연결실패

          if (error != null) {
            //console.log(error);
            stompUtil.setConnected(false);

            // 재연결 시도
            setTimeout(this.connect, 3000);
          }
        },
      );
    },

    /**
     * 웹소켓 연결종료
     * Stomp / Socket-Io
     */
    disconnect() {
      //this.changeUserStat("CHATOFF");
      if (stompUtil.isConnected()) {
        stompUtil.disconnect();
      }
    },

    /*
    * 메시지 전송
    *
    * */
    sendMsg() {
      let msgcopy = this.CHAT_MSG;
      if (msgcopy.replace(/(?:\r\n|\r|\n)/g, "").length === 0) {
        return false;
      }

      // let MSG_INFO = [];
      // if(msgcopy.length < 1000){
      //   MSG_INFO[0] = {
      //     msg: msgcopy,
      //     message_type: "TX",
      //     SNDRCV_CD: "SND",
      //     type: "message",
      //   };
      // } else if(msgcopy.length >= 1000){
      //   let ARR_NUM = (msgcopy.length / 1000) + 1;
      //   for(let i=0;i<ARR_NUM;i++){
      //     MSG_INFO[i] = {
      //     msg: msgcopy.substring((i*1000), 1000),
      //     message_type: "TX",
      //     SNDRCV_CD: "SND",
      //     type: "message",
      //   };
      //   }
      // }

                let MSG_INFO = {'msg' : msgcopy, message_type : 'TX', SNDRCV_CD: 'SND', type : 'message'}

      let CHAT_INFO = {
        talk_contact_id: this.TALK_CONTACT_ID,
        talk_sender_key: this.ASP_SENDER_KEY,
        call_typ_cd: "TTALK",
        aspNewCustKey: this.ASP_NEWCUST_KEY,
        talk_user_key: this.TALK_USER_KEY,
        SEND_USER_NM: this.$store.getters["userStore/GE_USER_ROLE"].userName,
        token: this.$store.state.messages.token,
        userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      };

      // let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
      //   user_key: CHAT_INFO.talk_user_key,
      //   sndrcv_cd: "snd",
      //   type: MSG_INFO.message_type,
      //   content: MSG_INFO.msg,
      //   talk_contact_id: CHAT_INFO.talk_contact_id,
      //   IMAGE_TALK_PATH:
      //     MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
      //   FILE_GROUP_KEY:
      //     MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
      //   FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
      //   FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
      //   FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
      //   //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
      //   SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
      //   SEND_USER_SHORT_NM: "나",
      //   consultEnd: MSG_INFO.consultEnd,
      //   ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
      // });

      // let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
      //   talk_contact_id: CHAT_INFO.talk_contact_id,
      // });


          // let MSG_INFO = {'msg' : msgcopy, message_type : 'TX', SNDRCV_CD: 'SND', type : 'message'}
          // let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
          //                 , 'talk_sender_key' : this.CLK_SENDER
          //                 , 'call_typ_cd' : this.CALL_TYP_CD
          //                 , 'aspNewCustKey' : this.CUST
          //                 , 'talk_user_key': this.CLK_USER
          //                 , 'SEND_USER_NM' : this.USER_NM
          //                 , 'token' : this.$store.state.messages.token
          //                 , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
          //                 };
  
          let chatmsg = this.$store.getters['messages/GE_MSG_OBJ',{
                        user_key: CHAT_INFO.talk_user_key
                        , sndrcv_cd: 'snd'
                        , type: MSG_INFO.message_type
                        , content: MSG_INFO.msg
                        , talk_contact_id: CHAT_INFO.talk_contact_id
                        , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                        , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                        , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                        , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                        , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                        , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                        , SEND_USER_SHORT_NM : '나'
                        , consultEnd : MSG_INFO.consultEnd
                        , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                    }];
  
          let msgObj = this.$store.getters['messages/GE_CONTACT_MSG', { talk_contact_id: CHAT_INFO.talk_contact_id }];


          stompUtil.sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj)



      // for(let n=0;n<MSG_INFO.length;n++){
      //   stompUtil.sendMessage(MSG_INFO[n], CHAT_INFO, chatmsg, msgObj);
      // }
      console.log("MSG_INFO", MSG_INFO);
      console.log("CHAT_INFO", CHAT_INFO);
      console.log("chatmsg", chatmsg);
      console.log("msgObj", msgObj);
      this.CHAT_MSG = "";

      this.getTalkHistList();
    },

    /**
     * 채팅가능상태 만들기
     */
    changeUserStat(chat) {
      apiChat
        .post(
          "api/chat/main/cnslr-sttus/regist",
          {
            USER_STATUS_CD: chat,
            USER_ID: this.USER_ID,
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
          },
          {
            head: {
              SERVICE: "api.chat.main.cnslr-sttus",
              METHOD: "regist",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((ret) => {
          if(chat == "CHATON"){
            console.log("상담 가능");
          } else if(chat == "CHATOFF"){
            console.log("상담 불가능");
          }
        });
    },
    
    scrlToTop() {
      this.scrollTarget = document.querySelector(
        ".layout-chatmain--chat-history-box"
      );

      if (this.scrollTarget) {
        this.scrollTarget.scrollTop = 0;
      }
    },

    scrlToBottom() {
      this.scrollTarget = document.querySelector(
        ".layout-chatmain--chat-history-box"
      );

      if (this.scrollTarget) {
        this.scrollTarget.scrollTop = this.scrollTarget.scrollHeight;
      }
    },

    //상담원 전체 고객사 키
    fullCust() {
      let CUST = "";
      for (
        let i = 0;
        i < this.$store.getters["userStore/GE_USER_ROLE"].company.length;
        i++
      ) {
        if (i == 0) {
          CUST = this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        } else {
          CUST =
            CUST +
            "," +
            this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        }
      }

      return CUST;
    },

    //상담 목록 조회
    getTalkList(detail) {
      apiChat
        .post(
          "api/chat/main/cnslt/inqire", //api url입력
          {
            TYPE: "ready;talking;callback;post",
            SORT_BY: "TALK_DIST_DT",
            DECENDING: "ASC",
            TALK_USER_KEY: "",
            ASP_USER_ID: this.USER_ID,
            ASP_CUST_KEY: this.CUST,
            ST_DATE: '',
            END_DATE: '',
            CALL_TYP_CD: '',
          },
          { head: {} }
        )
        .then((response) => {

          this.$store
            .dispatch("messages/AC_INIT_TALK_LIST", response.data.data)
            .then(() => {
              this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {
                selected_stat: this.selected_stat,
                userId: this.$store.getters["userStore/GE_USER_ID"],
                aspCustKey: this.CUST,
              });
            });

          //console.log("this.chatWaitList>>>><<<", this.chatWaitList);
          //console.log("this.chatList>>>><<<", this.chatList);
          this.scrlToBottom();
        })
        .catch((err) => {
          console.log("결과<<<<<", err);
          this.mainAlert(err);
        });
    },

//종료링크 보내기
    sendEndLink() {
      let strMsg = "고객님께 종료 버튼을 전송하였습니다.";

      let MSG_INFO = {
        msg: strMsg,
        message_type: "LI",
        SNDRCV_CD: "SND",
        type: "chatClose",
      };
      let CHAT_INFO = {
        talk_contact_id: this.TALK_CONTACT_ID,
        talk_sender_key: this.ASP_SENDER_KEY,
        call_typ_cd: "TTALK",
        aspNewCustKey: this.ASP_NEWCUST_KEY,
        talk_user_key: this.TALK_USER_KEY,
        SEND_USER_NM: this.USER_ID,
        token: window.sessionStorage.getItem("token"),
        userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      };


      
      let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
        user_key: CHAT_INFO.talk_user_key,
        sndrcv_cd: "snd",
        type: MSG_INFO.message_type,
        content: MSG_INFO.msg,
        talk_contact_id: CHAT_INFO.talk_contact_id,
        IMAGE_TALK_PATH:
          MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
        FILE_GROUP_KEY:
          MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
        FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
        FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
        FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
        SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
        SEND_USER_SHORT_NM: "나",
        consultEnd: MSG_INFO.consultEnd,
        ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
      });

      let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
        talk_contact_id: CHAT_INFO.talk_contact_id,
      });

      stompUtil.sendMessage(MSG_INFO, CHAT_INFO, chatmsg, msgObj);
    },

    showDialogImg(menu) {
      let dataObj = {
        CLK_CUST: this.ASP_NEWCUST_KEY,
        CLK_CHAT: this.TALK_CONTACT_ID,
        CLK_USER: this.TALK_USER_KEY,
        CLK_SENDER: this.ASP_SENDER_KEY,
        CLK_MSGSKIP: '',
        CLK_STAT: '',
        CLK_ICON: '',
        CLK_IMG: '',
        STATUS: "CHATON",
      };
      this.Imgdata = dataObj;
      console.log("open img!");
      console.log(this.Imgdata);
      this[`dialog${menu}`] = true;
    },

    /**
     * 모달창 숨기기
     */
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },

    //상담 종료(상담중 채팅을 후처리 상태로 만들기)
    endTalk(){

      apiChat.post("api/setting/consulttype/manage/inqire",   //api url입력
      {
        method : 'post',
        action : "api/chat/main/end-mssage/inqire",
        system_id : 'MSG34895' ,
        ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY : this.CUST,
        USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      {head: {
      }})
      .then((response) => {
        let MSG_INFO = {
          'type' : 'system',
          consultEnd: 'Y',
          msg: response.data.data,
          message_type: "TX",
          SNDRCV_CD: "SND",
          type: "message",
        };
        let CHAT_INFO = {
          talk_contact_id: this.TALK_CONTACT_ID,
          talk_sender_key: this.ASP_SENDER_KEY,
          call_typ_cd: "TTALK",
          aspNewCustKey: this.ASP_NEWCUST_KEY,
          talk_user_key: this.TALK_USER_KEY,
          SEND_USER_NM: this.USER_ID,
          token: window.sessionStorage.getItem("token"),
          userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        };
        let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
          user_key: CHAT_INFO.talk_user_key,
          sndrcv_cd: "snd",
          type: MSG_INFO.message_type,
          content: MSG_INFO.msg,
          talk_contact_id: CHAT_INFO.talk_contact_id,
          IMAGE_TALK_PATH:
            MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
          FILE_GROUP_KEY:
            MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
          FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
          FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
          FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
          //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
          SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
          SEND_USER_SHORT_NM: "나",
          consultEnd: MSG_INFO.consultEnd,
          ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
        });

        let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
          talk_contact_id: CHAT_INFO.talk_contact_id,
        });

        stompUtil.sendMessage(MSG_INFO, CHAT_INFO, chatmsg, msgObj);

        this.$emit("chatEnd", this.chatData);
      })
      .catch((err) => {
        this.mainAlert(err);
      })
    },
    showAlertEndTalk() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '상담을 종료하시겠습니까?',
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYesEndTalk,
        callNo: this.callNoEndTalk,
      });
    },
    callYesEndTalk() {
      //console.log("call yes");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.endTalk();
    },
    callNoEndTalk() {
      //console.log("call No");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    }
  },
  filters: {

  },
  computed: {

  },

  watch: {
    chatInfo() {
      this.ASP_NEWCUST_KEY = this.chatInfo.ASP_NEWCUST_KEY;
      this.TALK_CONTACT_ID = this.chatInfo.TALK_CONTACT_ID;
      this.ASP_SENDER_KEY = this.chatInfo.ASP_SENDER_KEY
      this.TALK_USER_KEY = this.chatInfo.TALK_USER_KEY
      this.USER_ID = this.chatInfo.USER_ID
      
      this.getTalkHistList();

      if(this.HAS_BTN){
        //this.changeUserStat("CHATON");
        this.setTalkEnv();
        this.connect();
      }
    }
  }
}
</script>

<style></style>
